@charset 'UTF-8';

@import "_vars";

/* Main */
main {
  h1 {
    margin-bottom: 40px;
    @include max-screen($sp_size) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  h2 {
    text-align: left;
    border-bottom: 1px solid $color_border_gray;
    padding-bottom: 5px;
    @include max-screen($sp_size) {
      margin-bottom: 20px;
    }
  }

  .register_wrapper {
    @include flexbox;
    @include min-screen($pc_size) {
      padding-left: 20px;
    }
    @include max-screen($sp_size) {
      display: block;
    }
    .register_main {
      width: calc(100% - 250px);
      @include max-screen($pc_size) {
        width: 100%;
      }

      .form_inner {
        & > .btn {
          width: 100%;
          height: 50px;
          margin-top: 30px;
          font-size: 16px;
          @include min-screen($pc_size) {
            display: none;
          }
        }
      }
    }

    .register_right {
      @include min-screen($pc_size) {
        width: 235px;
        margin-top: -80px;
        margin-left: 15px;
      }
      @include max-screen($sp_size) {
        width: 100%;
      }

      .support {
        color: $color_main_text;
        font-size: 14px;
        font-weight: bold;
      }

      .register_right_box {
        border: 1px solid $color_border_gray;
        margin-top: 10px;
        padding: 10px;

        p {
          margin-bottom: 5px;
          line-height: 1.8;

          &:first-child {
            margin-bottom: 10px;
          }

          &.bold {
            font-size: 13px;
          }
        }
      }
    }

    .submit_area {
      margin-top: 40px;
      @include max-screen($sp_size) {
        display: none;
      }
    }      
  }
}

.page_catalog {
  .catalog_top {
    @include flexbox;
    margin-bottom: 80px;
    @include max-screen($sp_size) {
      margin-bottom: 40px;
    }

    .catalog_text {
      margin-left: 20px;
      @include max-screen($sp_size) {
        margin-left: 0;
        margin-top: 10px;
      }
      p {
        @include min-screen($pc_size) {
          line-height: 1.6;          
        }

        &:nth-of-type(1) {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
          @include max-screen($sp_size) {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }

        &:nth-of-type(2) {
          font-size: 14px;
          margin-bottom: 20px;
          @include max-screen($sp_size) {
            margin-bottom: 10px;
          }
        }

        &.note {
          @include max-screen($sp_size) {
            font-size: 10px;            
          }
        }
      }
    }
  }

  .catalog_login {
    @include flexbox;
    align-items: center;
    margin-bottom: 40px;
    @include max-screen($sp_size) {
      align-items: flex-start;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      @include max-screen($sp_size) {
        width: calc(100% - 110px);
      }
    }

    .btn {
      width: 120px;
      height: 30px;
      margin-left: 20px;
      font-weight: normal;
      @include max-screen($sp_size) {
        width: 90px;
      }
    }
  }

  .form_inner_text {
    display: inline-block;
    margin-top: 30px;
    font-size: 16px;
    @include max-screen($sp_size) {
      font-size: 14px;
    }
  }

  .submit_area {
    .btn {
      @include max-screen($sp_size) {
        font-size: 16px;
      }      
    }
  }
}