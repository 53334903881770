/* Main */
main h1 {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  main h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

main h2 {
  text-align: left;
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 5px;
}

@media screen and (max-width: 767px) {
  main h2 {
    margin-bottom: 20px;
  }
}

main .register_wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  main .register_wrapper {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  main .register_wrapper {
    display: block;
  }
}

main .register_wrapper .register_main {
  width: calc(100% - 250px);
}

@media screen and (max-width: 768px) {
  main .register_wrapper .register_main {
    width: 100%;
  }
}

main .register_wrapper .register_main .form_inner > .btn {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  main .register_wrapper .register_main .form_inner > .btn {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  main .register_wrapper .register_right {
    width: 235px;
    margin-top: -80px;
    margin-left: 15px;
  }
}

@media screen and (max-width: 767px) {
  main .register_wrapper .register_right {
    width: 100%;
  }
}

main .register_wrapper .register_right .support {
  color: #435e54;
  font-size: 14px;
  font-weight: bold;
}

main .register_wrapper .register_right .register_right_box {
  border: 1px solid #d0d0d0;
  margin-top: 10px;
  padding: 10px;
}

main .register_wrapper .register_right .register_right_box p {
  margin-bottom: 5px;
  line-height: 1.8;
}

main .register_wrapper .register_right .register_right_box p:first-child {
  margin-bottom: 10px;
}

main .register_wrapper .register_right .register_right_box p.bold {
  font-size: 13px;
}

main .register_wrapper .submit_area {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  main .register_wrapper .submit_area {
    display: none;
  }
}

.page_catalog .catalog_top {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .page_catalog .catalog_top {
    margin-bottom: 40px;
  }
}

.page_catalog .catalog_top .catalog_text {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .page_catalog .catalog_top .catalog_text {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) {
  .page_catalog .catalog_top .catalog_text p {
    line-height: 1.6;
  }
}

.page_catalog .catalog_top .catalog_text p:nth-of-type(1) {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .page_catalog .catalog_top .catalog_text p:nth-of-type(1) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.page_catalog .catalog_top .catalog_text p:nth-of-type(2) {
  font-size: 14px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .page_catalog .catalog_top .catalog_text p:nth-of-type(2) {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .page_catalog .catalog_top .catalog_text p.note {
    font-size: 10px;
  }
}

.page_catalog .catalog_login {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .page_catalog .catalog_login {
    align-items: flex-start;
    margin-bottom: 20px;
  }
}

.page_catalog .catalog_login p {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .page_catalog .catalog_login p {
    width: calc(100% - 110px);
  }
}

.page_catalog .catalog_login .btn {
  width: 120px;
  height: 30px;
  margin-left: 20px;
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  .page_catalog .catalog_login .btn {
    width: 90px;
  }
}

.page_catalog .form_inner_text {
  display: inline-block;
  margin-top: 30px;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .page_catalog .form_inner_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .page_catalog .submit_area .btn {
    font-size: 16px;
  }
}
